import React from "react";
import HeadingDescription from "../common/headingDescription";
import CategoryButton from "../common/categoryButton";
import FindRoleItem from "./findRoleItem";
import CantFindRole from "./cantFindRole";
import ApplyNowButton from "../common/applyNowButton";
import AvailableJobs from "../../types/availableJobs";
import MaxWidthWrapper from "../../components/common/MaxWidthWrapper";
import SecondaryWidthWrapper from "../../components/common/SecondaryWidthWrapper";

interface findRoleProps {
  findRoleData: {
    attributes: {
      contactUsTitle: string;
      contactUsDescription: string;
      contactUsButton: {
        text: string;
        url: string;
      };
      titleSection: {
        primaryTitle: string;
        secondaryTitle: string;
        primaryDescription: string;
        secondaryDescription: string;
      };
      availableJobs: {
        buttons: {
          text: string;
          url: string;
        };
        profile_in_company: {
          data: {
            attributes: {
              jobTitle: string;
              imageData: {
                altText: string;
                url: string;
                media: {
                  data: {
                    attributes: {
                      url: string;
                    };
                  }[];
                };
              };
              job_category: {
                data: {
                  attributes: {
                    Name: string;
                    slug: string;
                  };
                };
              };
            };
          };
        };
      }[];
    };
  };
  availableJobs: AvailableJobs[];
  findRoleCategory: string[] | undefined;
  setSelectedCate(cate: string): void;
  selectedCate: string;
  pageUrl: string;
}

const FindRole: React.FC<findRoleProps> = (props) => {
  const jobRoleNotExistMessage = "Sorry we are not open for this role";
  return (
    <MaxWidthWrapper>
      <SecondaryWidthWrapper>
        <div
          className={
            "pt-[32px] pb-[81px] xl:pt-[68px] xl:pb-[98px] 2xl:pt-[68px] 2xl:pb-[98px]"
          }
        >
          <div className={"xl:flex xl:w-full xl:flex-col xl:gap-y-[130px]"}>
            <div
              className={
                "pb-[65px] xl:flex xl:flex-col xl:items-center xl:pb-0"
              }
            >
              <div>
                <HeadingDescription
                  blackHeading={
                    props?.findRoleData?.attributes?.titleSection?.primaryTitle
                  }
                  greenHeading={
                    props?.findRoleData?.attributes?.titleSection
                      ?.secondaryTitle
                  }
                  textAlignment={"text-center"}
                  fontWeight={"font-semibold"}
                />
              </div>
              <div
                className={
                  "flex flex-wrap justify-center gap-y-3 gap-x-[8px] xl:flex xl:gap-x-[21px]"
                }
              >
                {props?.findRoleCategory?.map((category, index) => (
                  <CategoryButton
                    key={index}
                    clickHandler={props.setSelectedCate}
                    isActive={props.selectedCate === category}
                    text={category}
                  />
                ))}
              </div>
            </div>
            <div
              className={
                "flex flex-col gap-y-[58px]  xl:flex xl:flex-col xl:gap-y-[77px]"
              }
            >
              {/*desktop view*/}
              {props?.availableJobs?.length ? (
                <div
                  className={
                    "xl:gap-27 hidden xl:block xl:grid xl:w-full xl:grid-cols-2 xl:gap-y-[37px] xl:gap-x-[26px]"
                  }
                >
                  {props?.availableJobs?.map((role, index) => (
                    <FindRoleItem
                      pageUrl={props.pageUrl}
                      key={index}
                      roleData={role}
                      icon={`${role?.profile_in_company?.data?.attributes?.imageData?.media?.data?.[0]?.attributes?.url}`}
                      description={
                        role?.profile_in_company?.data?.attributes?.jobTitle
                      }
                    />
                  ))}
                </div>
              ) : (
                <div className={"text-center"}>
                  <p className={"text-lg text-primary"}>
                    {jobRoleNotExistMessage}
                  </p>
                </div>
              )}

              {/*mobile view*/}
              <div
                className={
                  "flex flex-col items-center justify-center gap-y-[36px] md:grid md:grid-cols-2 md:gap-x-4 lg:grid lg:grid-cols-2 lg:place-items-center lg:gap-x-10 xl:hidden"
                }
              >
                {props?.availableJobs?.map((role, index) => (
                  <div
                    key={index}
                    className={
                      "flex h-[263px] w-[331px] items-end justify-center lg:bg-white xl:h-[260px] xl:w-[300px] xl:bg-white"
                    }
                  >
                    <div
                      className={
                        "relative flex flex h-[265px] w-full flex-col items-center justify-center gap-y-[18px]"
                      }
                    >
                      <div
                        className={
                          "flex w-[200px] flex-col items-center justify-center gap-y-[24px]"
                        }
                      >
                        <div className={""}>
                          <img
                            className={"w-[60px] lg:w-[80px] "}
                            src={`${role?.profile_in_company?.data?.attributes?.imageData?.media?.data?.[0]?.attributes?.url}`}
                            alt={`${role?.profile_in_company?.data?.attributes?.imageData?.altText}`}
                          />
                        </div>
                        <p
                          className={
                            "text-center text-[15px] font-medium text-[#111111]"
                          }
                        >
                          {role?.profile_in_company?.data?.attributes?.jobTitle}
                        </p>
                      </div>
                      <div>
                        <ApplyNowButton
                          pageUrl={props.pageUrl}
                          roleData={role}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        "absolute w-[280px] bg-primary p-0.5 lg:w-[331px] xl:w-[300px]"
                      }
                    ></div>
                  </div>
                ))}
                <div className={"block md:pl-5 lg:hidden xl:hidden"}>
                  <CantFindRole
                    contactUsButton={
                      props?.findRoleData?.attributes?.contactUsButton
                    }
                    contactUsTitle={
                      props?.findRoleData?.attributes?.contactUsTitle
                    }
                    contactUsDescription={
                      props?.findRoleData?.attributes?.contactUsDescription
                    }
                  />
                </div>
              </div>
              <div
                className={
                  "flex hidden justify-start lg:block lg:pl-[55px] xl:block"
                }
              >
                <CantFindRole
                  contactUsButton={
                    props?.findRoleData?.attributes?.contactUsButton
                  }
                  contactUsTitle={
                    props?.findRoleData?.attributes?.contactUsTitle
                  }
                  contactUsDescription={
                    props?.findRoleData?.attributes?.contactUsDescription
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </SecondaryWidthWrapper>
    </MaxWidthWrapper>
  );
};

export default FindRole;
