import React from "react";

function DoWhatYouLoveSectionCurve() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="555"
      height="1342"
      fill="none"
      viewBox="0 0 555 1342"
    >
      <path
        fill="#B0C5B2"
        fillOpacity="0.6"
        fillRule="evenodd"
        d="M555 .09C387.677 81.525 264.416 212.941 187.324 370.338a878.946 878.946 0 00-1.96 4.027 731.487 731.487 0 00-2.803 3.516C-20.405 633.9-59.075 1012.78 91.486 1320.49c3.523 7.18 7.118 14.35 10.785 21.51h1.124c-3.745-7.3-7.416-14.62-11.012-21.95-149.736-306.02-112.09-682.457 88.249-938.102C50.326 658.627 60.377 1012.29 221.795 1316.02c4.618 8.67 9.323 17.33 14.114 25.98h2.287c-4.972-8.96-9.851-17.93-14.636-26.92C60.838 1008.9 52.248 652.093 186.223 374.898c65.043-81.055 146.708-149.57 244.189-199.289 40.013-20.413 81.682-37.688 124.588-51.939v-2.108c-43.222 14.322-85.196 31.706-125.497 52.266-95.028 48.467-175.057 114.737-239.35 193.044C267.213 211.526 389.519 81.865 555 1.202V.09z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default DoWhatYouLoveSectionCurve;
