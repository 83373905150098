import React from 'react';
import WhyWorkListItem from '../whyWorkListItem/whyWorkListItem';
import HeadingDescription from '../common/headingDescription';
import { primaryBlockDataType } from '../../types/PrimaryBlock.type';
import MaxWidthWrapper from '../../components/common/MaxWidthWrapper';
import SecondaryWidthWrapper from '../../components/common/SecondaryWidthWrapper';

interface whyWorkProps {
  whyWorkData: primaryBlockDataType;
}

const WhyWork: React.FC<whyWorkProps> = ({ whyWorkData }) => {
  return (
    <div className={'pb-[32px] xl:pb-[49px] xl:pt-[74px]'}>
      <div className={'bg-[#F5FDF6]'}>
        <MaxWidthWrapper>
          <SecondaryWidthWrapper>
            <div
              className={
                'flex flex-col items-center justify-center gap-y-[58px] pt-[32px] pb-[50px] xl:gap-y-[90px] xl:pt-[51px] xl:pb-[96px] 2xl:gap-y-[90px] 2xl:pt-[51px] 2xl:pb-[96px]'
              }
            >
              <div
                className={
                  'flex flex-col items-center gap-y-[40px]  xl:w-full  xl:gap-y-[90px]'
                }
              >
                <HeadingDescription
                  blackHeading={whyWorkData.titleSection.primaryTitle}
                  greenHeading={whyWorkData.titleSection.secondaryTitle}
                  blackDescription={whyWorkData.titleSection.primaryDescription}
                  gap={32}
                  textAlignment={'text-center'}
                  descriptionWidth={'w-[70%]'}
                />
                {/* <div
                  className={
                    'xl:gap-43  2xl:gap-43 flex  flex-col content-center items-center gap-y-[20px] gap-x-[40px] md:grid md:grid-cols-2 lg:grid lg:grid-cols-2 xl:grid xl:w-full xl:grid-cols-3 2xl:grid 2xl:w-full 2xl:grid-cols-3'
                  }
                > */}
                <div className="flex flex-col justify-center md:flex-row md:gap-[30px] md:px-6 lg:gap-[60px]">
                  {whyWorkData.dataSection.map((item, index) => (
                    <WhyWorkListItem
                      key={index}
                      icon={`${item?.imageData?.media}`}
                      text={item.title}
                    />
                  ))}
                </div>
              </div>
              <div
                className={
                  'rounded-[7px] bg-[#66C893] py-2 px-10 md:px-[28px] md:py-[12px]'
                }
              >
                <a href={'#findRole'}>
                  <p
                    className={
                      'text-[14px] font-[600] text-white md:text-[20px] lg:text-[23px]'
                    }
                  >
                    {whyWorkData?.button?.text}
                  </p>
                </a>
              </div>
            </div>
          </SecondaryWidthWrapper>
        </MaxWidthWrapper>
      </div>
    </div>
  );
};

export default WhyWork;
