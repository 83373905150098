import React from "react";
import { SecondaryBlockDataType } from "../../types/SecondaryBlock.type";
import Star from "../svg/JoinUsPage/star";
import NextDestinationLeftCurve from "../svg/JoinUsPage/nextDestinationLeftCurve";
import MaxWidthWrapper from "../../components/common/MaxWidthWrapper";
import SecondaryWidthWrapper from "../../components/common/SecondaryWidthWrapper";

interface nextDestinationProps {
  nextDestinationData: SecondaryBlockDataType;
}

const NextDestination: React.FC<nextDestinationProps> = ({
  nextDestinationData,
}) => {
  return (
    <MaxWidthWrapper>
      <SecondaryWidthWrapper>
        <div
          className={
            "pt-[32px] pb-[32px] " +
            "lg:flex lg:w-full lg:items-center lg:justify-center lg:px-[40px] " +
            "xl:flex xl:items-center xl:justify-center xl:px-0 xl:pt-[49px] xl:pb-[68px] "
          }
        >
          <div
            className={
              "flex flex-col-reverse items-center gap-y-[36px] " +
              "lg:flex lg:flex-row xl:items-center xl:justify-center " +
              "xl:flex xl:w-full xl:flex-row xl:items-center xl:justify-center" +
              "2xl:flex 2xl:flex-row 2xl:justify-between"
            }
          >
            <div
              className={
                "flex w-full justify-center lg:w-2/4 xl:w-2/4 2xl:w-2/4"
              }
            >
              <div
                className={
                  "absolute top-[3150px] bottom-0 left-0 right-0 -z-10 hidden sm:hidden lg:block"
                }
              >
                <NextDestinationLeftCurve />
              </div>
              <div
                className={`flex flex-col items-center gap-y-[15px] 
            md:gap-y-[22px] 
            lg:items-start lg:gap-y-[25px] 
            xl:relative xl:flex xl:flex xl:flex-col xl:gap-y-[32px]
            2xl:flex-col 2xl:gap-y-[30px] 2xl:gap-y-[32px]`}
              >
                <div>
                  <h2
                    className={
                      "text-[18px] font-medium text-[#111111] md:text-[22px] " +
                      "md:font-semibold " +
                      "lg:text-[26px] lg:font-semibold " +
                      "xl:text-[35px] xl:font-semibold "
                    }
                  >
                    {nextDestinationData.titleSection.primaryTitle}{" "}
                    <span className={"text-primary"}>
                      {nextDestinationData.titleSection.secondaryTitle}
                    </span>
                  </h2>
                </div>
                <div
                  className={
                    "hidden xl:absolute xl:mt-[40px] xl:ml-[45px] xl:block 2xl:mt-[45px] 2xl:ml-[60px]"
                  }
                >
                  <Star />
                </div>
                <div
                  className={`flex w-[90%] flex-col text-center text-[14px] md:w-4/5 
              lg:w-3/4 lg:text-left lg:text-left lg:text-[17px] 
              xl:w-full xl:text-[25px] 
              2xl:w-4/5 2xl:text-left 2xl:text-[25px]`}
                >
                  <p
                    className={`leading-4 tracking-tight text-description-tex-color md:leading-5 lg:leading-7 xl:leading-9 xl:tracking-wide 2xl:leading-9`}
                  >
                    {nextDestinationData.titleSection.primaryDescription}
                  </p>
                </div>
              </div>
            </div>
            <div className={"flex justify-center xl:w-2/4 2xl:w-2/4 "}>
              <img
                className={"w-2/4 lg:w-9/12 xl:w-full 2xl:w-full"}
                src={`${nextDestinationData?.imageData?.[0]?.media}`}
                alt={nextDestinationData?.imageData?.[0]?.altText}
              />
              {/*<TeamPosterImage />*/}
            </div>
          </div>
        </div>
      </SecondaryWidthWrapper>
    </MaxWidthWrapper>
  );
};

export default NextDestination;
