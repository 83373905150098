import React from "react";

interface WhyWorkListItemProps {
  icon: string;
  text: string;
}

const WhyWorkListItem: React.FC<WhyWorkListItemProps> = ({ icon, text }) => {
  return (
    <div
      className={
        "flex h-[170px] w-[207px]  flex-col justify-end bg-green-200 md:h-[190px] md:w-[230px] lg:h-[265px] lg:w-[305px] lg:bg-white xl:h-[260px] xl:w-[300px] xl:bg-white 2xl:h-[265px] 2xl:w-[305px] 2xl:bg-white"
      }
    >
      <div className={"relative flex flex h-[265px] w-full justify-center"}>
        <div
          className={
            "flex w-[70%] flex-col items-center justify-center gap-y-[24px] py-[30px] xl:w-[65%]"
          }
        >
          <div>
            <img className={"w-[45px] lg:w-full "} src={icon} alt={""} />
          </div>
          <p
            className={
              "text-center text-[17px] font-medium text-[#111111] xl:text-[23px]"
            }
          >
            {text}
          </p>
        </div>
      </div>
      <div
        className={
          "hidden w-full bg-primary p-0.5 lg:block xl:absolute xl:block xl:w-[300px] 2xl:absolute 2xl:block 2xl:w-[305px]"
        }
      ></div>
    </div>
  );
};

export default WhyWorkListItem;
