import React, { FC } from "react";

interface Props {
  pageUrl: string;
  roleData: any;
}

const ApplyNowButton: FC<Props> = ({ pageUrl, roleData }) => {
  return (
    <div
      className={
        "rounded-[3px] bg-green-300 px-[40px] py-[8px] xl:flex xl:justify-center xl:bg-tertiary xl:px-[11px] xl:py-[10px]"
      }
    >
      <a
        href={`${pageUrl}/${roleData?.profile_in_company?.data?.attributes?.slug}`}
      >
        <button
          className={
            "text-sm font-semibold text-white lg:text-base xl:text-[17px] xl:font-medium"
          }
        >
          {roleData?.button?.text}
        </button>
      </a>
    </div>
  );
};

export default ApplyNowButton;
