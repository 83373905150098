import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Swiper styles
import "swiper/css";

import { Autoplay } from "swiper";
import { SecondaryBlockDataType } from "../../types/SecondaryBlock.type";
import SecondaryWidthWrapper from "../../components/common/SecondaryWidthWrapper";

interface doWhatYouLoveProps {
  doWhatYouLoveData: SecondaryBlockDataType;
  media: {
    altText: string;
    url: string;
    media: { data: { attributes: { url: string } }[] };
  }[];
}

const DoWhatYouLove: React.FC<doWhatYouLoveProps> = ({
  doWhatYouLoveData,
  media,
}) => {
  const firstSliderMedia = media[0].media?.data.map(
    (item) => `${item.attributes.url}`
  );
  const secondSliderMedia = media[1].media?.data.map(
    (item) => `${item.attributes.url}`
  );
  return (
    <div
      className={
        "flex w-full flex-col pt-[25px] pb-[74px] xl:items-center xl:justify-center xl:pt-[108px]"
      }
    >
      <div>
        <SecondaryWidthWrapper>
          <div
            className={
              "m-auto flex w-[85%] flex-col items-center gap-[12px] pb-[32px] md:gap-[32px] lg:pb-[128px]"
            }
          >
            <h3
              className={
                "text-center text-[18px] font-medium text-[#111111] md:text-[22px] md:font-semibold lg:text-[26px] xl:text-[35px]"
              }
            >
              {doWhatYouLoveData.titleSection.primaryTitle}
              <span className={"font-600 text-center text-primary"}>
                {doWhatYouLoveData.titleSection.secondaryTitle}
              </span>
            </h3>
            <div className={"text-[14px] lg:w-[80%]"}>
              <p
                className={
                  "font-400 text-center text-[#111111] md:text-[14px] lg:text-[17px] xl:text-[25px]"
                }
              >
                {doWhatYouLoveData.titleSection.primaryDescription}
              </p>
              <p
                className={
                  "font-500 text-center text-primary md:text-[14px] lg:text-[17px] xl:text-[25px]"
                }
              >
                {doWhatYouLoveData.titleSection.secondaryDescription}
              </p>
            </div>
          </div>
        </SecondaryWidthWrapper>
      </div>
      <div
        className={
          "flex w-full flex-col gap-y-[5px] 2xl:flex 2xl:flex-col 2xl:gap-y-[12px]"
        }
      >
        <div className={"w-full"}>
          <Swiper
            slidesPerView={3}
            spaceBetween={4}
            loop={true}
            autoplay={{
              delay: 2500,
              reverseDirection: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 3,
                spaceBetween: 7,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 12,
              },
            }}
            modules={[Autoplay]}
          >
            {firstSliderMedia.map((i) => (
              <SwiperSlide key={i}>
                <img
                  style={{
                    height: "300px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  src={i}
                  alt={""}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={"w-full"}>
          <Swiper
            // slidesPerView={1}
            spaceBetween={4}
            direction={"horizontal"}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 3,
                spaceBetween: 7,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 12,
              },
            }}
            modules={[Autoplay]}
            className={"mySwiper"}
          >
            {secondSliderMedia.map((i) => (
              <SwiperSlide key={i} style={{ height: "auto" }}>
                <img
                  style={{
                    height: "300px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  src={i}
                  alt={""}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default DoWhatYouLove;
