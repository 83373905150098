import React from "react";

interface cantFindRoleProps {
  contactUsTitle: string;
  contactUsDescription: string;
  contactUsButton: {
    text: string;
    url: string;
  };
}

const CantFindRole: React.FC<cantFindRoleProps> = (props) => {
  return (
    <div className={"xl:m-auto xl:max-w-[1600px]"}>
      <div className={"flex flex-col gap-y-[23px] xl:gap-y-[29px]"}>
        <div className={"text-[#111111]"}>
          <h3 className={"text-[15px] font-medium xl:text-[22px]"}>
            {props?.contactUsTitle}
          </h3>
          <p className={"text-sm font-light xl:text-lg"}>
            {props?.contactUsDescription}
          </p>
        </div>
        <div>
          <button
            className={
              "rounded-[4px] bg-primary py-2 px-10 text-sm font-medium text-white lg:text-base xl:bg-green-300 xl:py-[11px] xl:px-[17px] xl:text-[23px]"
            }
          >
            <a href={"mailto:info@propelius.tech"}>
              {props?.contactUsButton?.text}
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CantFindRole;
