import React from 'react';

interface CategoryButtonTextProps {
  text: string;
  isActive: boolean;
  clickHandler(buttonText: string): void;
}

const CategoryButton: React.FC<CategoryButtonTextProps> = (props) => {
  return (
    <>
      {props.isActive ? (
        <div
          className={`flex h-[25px] cursor-pointer items-center justify-center rounded-3xl bg-tertiary px-3 px-2 md:h-[29px] md:w-[90px] xl:h-[47px] xl:w-[149px]`}
          onClick={() => props.clickHandler(props.text)}
        >
          <p className={`text-[14px] text-white lg:text-[16px] xl:text-[25px]`}>
            {props.text}
          </p>
        </div>
      ) : (
        <div
          className={`flex h-[25px] cursor-pointer items-center justify-center rounded-3xl bg-inactiveButton px-3 md:h-[29px] md:w-[90px] xl:h-[47px] xl:w-[149px]`}
          onClick={() => props.clickHandler(props.text)}
        >
          <p
            className={`text-[14px] text-tertiary lg:text-[16px] xl:text-[25px]`}
          >
            {props.text}
          </p>
        </div>
      )}
    </>
  );
};

export default CategoryButton;
