import React from "react";

interface JobRoleProps {
  pageUrl: string;
  icon: any;
  description: string | undefined;
  roleData: any;
}

const FindRoleItem: React.FC<JobRoleProps> = (props) => {
  const roleData = props.roleData;
  return (
    <div
      className={
        "xl:flex xl:w-full xl:items-center xl:justify-between xl:gap-x-[76px] xl:bg-white xl:py-[33px] xl:pl-[19px] xl:pr-[66px] xl:drop-shadow-md xl:transition xl:duration-500 xl:hover:drop-shadow-lg xl:hover:ease-in-out"
      }
    >
      <div
        className={
          "xl:flex xl:w-[75%] xl:items-center xl:justify-start xl:gap-x-[13px]"
        }
      >
        <div>
          <img className={"w-[80px]"} src={props.icon} alt={"Job role icon"} />
        </div>

        <div>
          <p className={"xl:text-[20px] xl:font-medium"}>
            {props?.description}
          </p>
        </div>
      </div>
      <div className={"xl:w-[30%]"}>
        <div
          className={"rounded-[4px] bg-[#489977] px-[12px] py-[8px]"}
          style={{ boxShadow: "4px 4px 26px rgba(64, 186, 119, 0.188)" }}
        >
          <a
            href={`${props.pageUrl}/${roleData?.profile_in_company?.data?.attributes?.slug}`}
          >
            <p className={"text-center text-[22px] text-white"}>
              {roleData?.button?.text}
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FindRoleItem;
