import * as React from "react";
import { useEffect, useState } from "react";
import HeroPoster from "../components/heroPoster/heroPoster";
import DoWhatYouLove from "../components/joinUs/doWhatYouLove";
import WhyWork from "../components/joinUs/whyWork";
import NextDestination from "../components/joinUs/nestDestination";
import FindRole from "../components/joinUs/findRole";
import { primaryBlockDataHandler } from "../helper/api/primaryBlock.api";
import { secondaryBlockDataHandler } from "../helper/api/secondaryBlock.api";
import Layout from "../components/layout";
import AvailableJobs from "../types/availableJobs";
import LeftCurve from "../components/svg/leftCurve";
import RightCurve from "../components/svg/rightCurve";
import DoWhatYouLoveSectionCurve from "../components/svg/JoinUsPage/doWhatYouLoveSectionCurve";
import SeoDataHandler from "../helper/handler/seoData.handler";
import { SEO } from "../components/seo";

const JoinUs: React.FC<any> = ({ pageContext }) => {
  console.log(pageContext?.joinUsPageData?.pageData?.data[0]?.attributes?.url);
  const [selectedCate, setSelectedCate] = useState<string>("All");
  const [findJobRoles, setFindJobRoles] = useState<any>();
  const [allAvailableJobs, setAllAvailableJobs] = useState<AvailableJobs[]>();
  const [selectAvailableJob, setSelectAvailableJob] = useState<any>();

  const whyWorkWithUsData = primaryBlockDataHandler(
    pageContext?.whyWorkWithCompanyData
  );
  const doWhatYouLoveData = secondaryBlockDataHandler(
    pageContext?.doWhatYouLove
  );
  const yourNextDestinationData = secondaryBlockDataHandler(
    pageContext?.yourNextDestination
  );

  useEffect(() => {
    setFindJobRoles(pageContext.availableJobs);
    setAllAvailableJobs(pageContext.availableJobs.attributes.availableJobs);
    setSelectAvailableJob(pageContext.availableJobs);
  }, [pageContext.availableJobs]);

  useEffect(() => {
    if (selectedCate !== "All") {
      const filterData = allAvailableJobs?.filter(
        (d1) =>
          d1?.profile_in_company?.data?.attributes?.job_category?.data
            ?.attributes?.Name === selectedCate
      );
      setSelectAvailableJob(filterData);
    } else {
      setSelectAvailableJob(allAvailableJobs);
    }
  }, [selectedCate, allAvailableJobs]);

  return (
    <Layout
      footerData={pageContext?.common?.footerData}
      navbarData={pageContext?.common?.navbarData}
    >
      <div>
        <div className={"-z-1 absolute left-0 hidden lg:block"}>
          <LeftCurve />
        </div>
        <div className={"-z-1 absolute right-0 hidden lg:block"}>
          <RightCurve />
        </div>
        <HeroPoster
          pageData={pageContext?.joinUsPageData?.pageData}
          coverUrl={pageContext?.joinUsPageData?.pageMedia}
        />
        <div className={"absolute right-0 -z-10 hidden lg:block"}>
          <DoWhatYouLoveSectionCurve />
        </div>
        <DoWhatYouLove
          doWhatYouLoveData={doWhatYouLoveData}
          media={pageContext?.doWhatYouLove?.data?.[0]?.attributes?.imageData}
        />
        <WhyWork whyWorkData={whyWorkWithUsData} />
        <div>
          <NextDestination nextDestinationData={yourNextDestinationData} />
          <div id={"findRole"}>
            <FindRole
              pageUrl={
                pageContext?.joinUsPageData?.pageData?.data[0]?.attributes?.url
              }
              findRoleData={findJobRoles}
              findRoleCategory={pageContext.jobCategory}
              availableJobs={selectAvailableJob}
              setSelectedCate={setSelectedCate}
              selectedCate={selectedCate}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default JoinUs;

export const Head: React.FC<any> = ({ pageContext }) => {
  const seoData = SeoDataHandler(pageContext?.joinUsPageData?.pageData);
  return (
    <SEO
      title={seoData.title}
      description={seoData.description}
      keywords={seoData.keywords}
      image={seoData.image}
      pathname={seoData.pagePath}
    />
  );
};
